import React from 'react';
import './NewMarquee.css';

const NewMarquee = () => {
    return (
        <>

            <div id="marqueeDiv">
                <p>Cholla trail reopens!</p>
            </div>

        </>
    )
}

export default NewMarquee;