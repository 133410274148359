// General imports
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Hamburger from "hamburger-react";
import './Navbar.css';

// Component imports
import Menu from "./Menu";

// Image imports (import any logo images here)
// import navbarLogo from "./img/sslogo.png";
import navbarLogo from './img/sslogoTwo.png';

const Navbar = (props) => {

    // State variables (for hamburger menu)
    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    return (

        <>

            {/* Hamburger Menu - Only visible on mobile */}

            <div className="HamburgerMenuDiv" style={{ display: hamburgerOpen ? 'inline' : 'none' }}>
                <Menu hamburgerstatus={hamburgerOpen} sethamburgerstatus={setHamburgerOpen} />
            </div>

            {/* Navbar */}

            <nav>
                <div className="navbar">
                    <img src={navbarLogo} alt="Navbar Logo" id="navbarLogo" />
                    <div className="navLinks">
                        <ul className="links">
                            <li><Link to="/" className="navigationLink">HOME</Link></li>
                            <li><Link to="/maps" className="navigationLink">MAPS</Link></li>
                            <li><Link to="/info" className="navigationLink">INFO</Link></li>
                            <li><Link to="/contact" className="navigationLink">CONTACT</Link></li>
                        </ul>
                    </div>
                    <div className="hamburgerContainer">
                        <Hamburger color="#D5600E" duration={0.7} rounded onToggle={toggled => {
                            if (toggled) {
                                setHamburgerOpen(!hamburgerOpen)
                            } else {
                                setHamburgerOpen(!hamburgerOpen)
                            }
                        }} />
                    </div>
                </div>
            </nav>

        </>

    )
}

export default Navbar;