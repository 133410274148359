import React, { useState } from 'react';
import './Home.css';
import ReactPlayer from "react-player";
import ReactWeather, { useOpenWeather } from 'react-open-weather';

// image imports
// import TempLogo from './img/sslogo.png';
import TempLogo from './img/sslogoTwo.png';
import WeatherIcon from './img/weatherIcon.png';

const Home = () => {

    // following applies to openWeather API for upcoming forecast
    const { data, isLoading, errorMessage } = useOpenWeather({
        key: '606f46b2c64e35797031acd70c8407ed',
        lat: '33.51559',
        lon: '-111.96200',
        lang: 'en',
        unit: 'imperial'
    });

    const customStyles = {
        gradientStart: '#E27846',
        gradientMid: '#E27846',
        gradientEnd: '#E27846',
        locationFontColor: '#FFF',
        todayTempFontColor: '#FFF',
        todayDateFontColor: '#FFF',
        todayRangeFontColor: '#FFF',
        todayDescFontColor: '#FFF',
        todayInfoFontColor: '#FFF',
        todayIconColor: '#FFF',
        forecastBackgroundColor: '#FFF',
        forecastSeparatorColor: '#DDD',
        forecastDateColor: '#777',
        forecastDescColor: '#777',
        forecastRangeColor: '#777',
        forecastIconColor: '#4BC4F7',
    }

    // Delay for fadeOut animation
    function fadeOutDelay() {
        setTimeout(fadeOutLogo, 3000);
    }

    // fadeOut animation for logo, subheader (& remove overlay)
    function fadeOutLogo() {
        var fadeTarget = document.getElementById("sslogoHomepage");
        var fadeTargetRemove = document.getElementById("homePageOverlay");
        var fadeEffect = setInterval(function () {
            if (!fadeTarget.style.opacity) {
                fadeTarget.style.opacity = 1;
            }
            if (fadeTarget.style.opacity > 0) {
                fadeTarget.style.opacity -= 0.1;
            } else {
                fadeTargetRemove.remove();
                clearInterval(fadeEffect);
            }
        }, 150);
    }

    return (

        <>

            <div id="homepageWrapper">
                <div id='homepageDiv'>
                    <ReactPlayer
                        url='https://www.youtube.com/watch?v=Ppyh84H3fjY'
                        className='reactPlayerHomepage'
                        playing={true}
                        muted={true}
                        height="100%"
                        width="100%">
                    </ReactPlayer>
                    <div id="homePageOverlay" onLoad={fadeOutDelay}></div>
                    <img src={TempLogo} alt="Scottsdale Sunset Logo" id="sslogoHomepage" onLoad={fadeOutDelay} />
                    <div id="weatherDiv">
                        <ReactWeather
                            style={{ height: "50rem", width: "50rem" }}
                            theme={customStyles}
                            isLoading={isLoading}
                            errorMessage={errorMessage}
                            data={data}
                            lang="en"
                            locationLabel="Camelback Mountain"
                            unitsLabels={{ temperature: '\u00B0 F', windSpeed: 'mph' }}
                            showForecast={true}
                        />
                    </div>
                </div>
                <div id="weatherDivAlt">
                    <ReactWeather
                        style={{ height: "50rem", width: "50rem" }}
                        theme={customStyles}
                        isLoading={isLoading}
                        errorMessage={errorMessage}
                        data={data}
                        lang="en"
                        locationLabel="Camelback Mountain"
                        unitsLabels={{ temperature: '\u00B0 F', windSpeed: 'mph' }}
                        showForecast={true}
                    />
                </div>
            </div>

        </>
    )
}

export default Home;