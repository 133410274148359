import React from 'react';
import Marquee from 'react-fast-marquee';
import './New.css';

// Component imports
import NewMarquee from './NewMarquee';

const New = () => {
    return (

        <div id='newsDiv'>
            <div className="newsRow">
                <h1 id='newsHeader'>News & Upcoming Events</h1>
            </div>
            <div className="newsRow">
                <Marquee id="newsMarqueeDiv" gradientWidth="20" speed="60">
                    <NewMarquee/>
                </Marquee>
            </div>
        </div>

    )
}

export default New;