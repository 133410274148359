import React from 'react';
import './BeatTheHeat.css';

// Image imports
import heatIndex from './img/heatIndex.jpg';
import heatSymptoms from './img/heatSymptoms.png';

const BeatTheHeat = () => {
    return (

        <div id='beatTheHeatDiv'>
            <div id="heatDivDesktop">
                <img src={heatIndex} alt="heat index chart" className='heatImage' />
                <img src={heatSymptoms} alt="heat symptoms chart" className='heatImage' />
            </div>
            <div id="heatDivTablet">
                <img src={heatIndex} alt="heat index chart" className='heatImage' />
                <img src={heatSymptoms} alt="heat symptoms chart" className='heatImage' />
            </div>
        </div>

    )
}

export default BeatTheHeat;