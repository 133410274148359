import React from 'react';
import './Rules.css';

const Rules = () => {
    return (

        <div id='rulesDiv'>
            <div className="rulesCol">
                <ul>
                    <li className='rulesLi'>Hours: Sunrise - Sunset (sunset closure strictly enforced)</li>
                    <li className='rulesLi'>Vehicles in parking lot after sunset subject to fines</li>
                    <li className='rulesLi'>No bikes permitted on trail</li>
                    <li className='rulesLi'>Glass beverage containers prohibited</li>
                    <li className='rulesLi'>Motorized vehicles restricted to designated parking areas & paved roads</li>
                </ul>
            </div>
            <div className="rulesCol">
                <ul>
                    <li className='rulesLi'>Destruction or removal of plants prohibited</li>
                    <li className='rulesLi'>Fires Prohibited</li>
                    <li className='rulesLi'>Standing and/or waiting for parking spaces prohibited</li>
                    <li className='rulesLi'>Hiking off trail prohibited</li>
                    <li className='rulesLi'>No drones allowed</li>
                </ul>
            </div>
        </div>

    )
}

export default Rules;