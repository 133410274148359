import React from 'react';
import Carousel from "react-bootstrap/Carousel";
import './Locals.css';

// Image imports
import chipmunk from './img/chipmunk.png';
import jackrabbit from './img/jackrabbit.png';
import lizard from './img/lizard.png';
import mouse from './img/mouse.png';
import rattlesnake from './img/rattlesnake.png';
import barrelCactus from './img/barrelCactus.png';
import brittleBush from './img/brittleBush.png';
import paloVerde from './img/paloVerde.png';
import saguaro from './img/saguaro.png';
import hedgehogCactus from './img/hedgehogCactus.png';

const Locals = () => {
    return (

        <div id='localsDiv'>
            <div id="localsCarouselDiv">
                <Carousel fade className='carouselContainer'>
                    <Carousel.Item interval={5000} className="carouselItem">
                        <img src={barrelCactus} alt="barrel cactus" className='localSlide' />
                    </Carousel.Item>
                    <Carousel.Item interval={5000} className="carouselItem">
                        <img src={brittleBush} alt="brittle bush" className='localSlide' />
                    </Carousel.Item>
                    <Carousel.Item interval={5000} className="carouselItem">
                        <img src={paloVerde} alt="palo verde" className='localSlide' />
                    </Carousel.Item>
                    <Carousel.Item interval={5000} className="carouselItem">
                        <img src={saguaro} alt="saguaro cactus" className='localSlide'></img>
                    </Carousel.Item>
                    <Carousel.Item interval={5000} className="carouselItem">
                        <img src={hedgehogCactus} alt="hedgehog cactus" className='localSlide'></img>
                    </Carousel.Item>
                </Carousel>
                <Carousel fade className='carouselContainer'>
                    <Carousel.Item interval={5000} className="carouselItem">
                        <img src={chipmunk} alt="chipmunk" className='localSlide' />
                    </Carousel.Item>
                    <Carousel.Item interval={5000} className="carouselItem">
                        <img src={jackrabbit} alt="jackrabbit" className='localSlide'></img>
                    </Carousel.Item>
                    <Carousel.Item interval={5000} className="carouselItem">
                        <img src={lizard} alt="lizard" className='localSlide'></img>
                    </Carousel.Item>
                    <Carousel.Item interval={5000} className="carouselItem">
                        <img src={mouse} alt="mouse" className='localSlide'></img>
                    </Carousel.Item>
                    <Carousel.Item interval={5000} className="carouselItem">
                        <img src={rattlesnake} alt="rattlesnake" className='localSlide'></img>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div id="localsCarouselLabels">
                <h2>Plant life</h2>
                <h2>Wild life</h2>
            </div>
        </div>

    )
}

export default Locals;