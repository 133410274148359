import React from 'react';
import './BePrepared.css';

// Image imports
import trailGear from './img/trailgear.png';
import boot from './img/boot.png';
import hat from './img/hat.png';
import phone from './img/phone.png';
import sunblock from './img/sunblock.png';
import waterbottle from './img/waterbottle.png';

const BePrepared = () => {
    return (

        <div id='bePreparedDiv'>
            <div id="trailGearImgDiv">
                <img src={trailGear} alt="recommended trail gear" id='trailGearImg' />
            </div>
            <div id="trailRatingsDiv">
                <h3>Trail Difficulty Ratings:</h3>
                <p>Bobby Rock Loop: Easy</p>
                <p>Cholla: Extremely Difficult</p>
                <p>Echo Canyon Trail: Extremely Difficult</p>
            </div>
            <div id="gearDiv">
                <div className="gearIconDiv">
                    <img src={boot} alt="boot" className='gearDivIcon boot' />
                    <p>Good shoes</p>
                </div>
                <div className="gearIconDiv">
                    <img src={hat} alt="hat" className='gearDivIcon hat' />
                    <p>Hat</p>
                </div>
                <div className="gearIconDiv">
                    <img src={phone} alt="phone" className='gearDivIcon phoneIcon' />
                    <p>Fully charged phone</p>
                </div>
                <div className="gearIconDiv">
                    <img src={sunblock} alt="sunblock" className='gearDivIcon sunblockIcon' />
                    <p>Sunblock</p>
                </div>
                <div className="gearIconDiv">
                    <img src={waterbottle} alt="waterbottle" className='gearDivIcon waterIcon' />
                    <p>Water! Bring at least 1 liter</p>
                </div>
            </div>
        </div>

    )
}

export default BePrepared;