// General imports
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import './Contact.css';

// Image imports
import contactLogo from './img/sslogoTwo.png';

const Contact = (props) => {

    const [status, setStatus] = useState("Submit");

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");
        const { name, email, message, messageMobile } = e.target.elements;
        let details = {
            name: name.value,
            email: email.value,
            message: message.value,
            messageMobile: messageMobile.value
        };
        let response = await fetch("https://dixonwebdev.com/backend/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(details),
        });
        setStatus("Submit");
        let result = await response.json();
        alert(result.status);

        navigate('/');
    };

    return (
        <>

            <div id="contactDiv">
                <h1 id="contactHeader">Contact Us</h1>
                <div id="contactFormDiv">
                    <div className="contactCol contactColLeft">
                        <img src={contactLogo} alt="Chicago Riverwalk Live Logo" id="contactFormLogo" />
                    </div>
                    <div className="contactCol contactColRight">
                        <form name="contactForm" id="contactForm" onSubmit={handleSubmit}>
                            <label htmlFor="name" className="label">Name: </label>
                            <input type="text" name="name" id="name" />
                            <label htmlFor="email" className="label">Email: </label>
                            <input type="email" name="email" id="email" />
                            <label htmlFor="message" className="label">Message: </label>
                            <textarea name="message" id="message" cols="60" rows="10"></textarea>
                            <textarea name="messageMobile" id="messageMobile" cols="42" rows="10"></textarea>
                            <button type="submit" id="submitButton">{status}</button>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Contact;