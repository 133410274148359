import React from 'react';
import './Maps.css';

// image imports
import trailMap from './img/camelbackMap.png';

const Maps = () => {
    return (
        <>

            <div id="mapPageWrapper">
                <div id="mapsBackgroundDivTop"></div>
                <div class="custom-shape-divider-top-1665200598">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
                    </svg>
                </div>
                <div id="mapsBackgroundDivBottom"></div>

                <div id='parkLocationDiv'>
                    <div id="desktopMapContainer">
                        <h2>Park Location</h2>
                        <iframe
                            className='desktopIframe'
                            title='desktopMap'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13305.896296719717!2d-111.97066736496721!3d33.51505789208612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b0c8f7c1849a3%3A0x9b645aa4b0b6aee0!2sCamelback%20Mountain!5e0!3m2!1sen!2sus!4v1665179552993!5m2!1sen!2sus"
                            width="900"
                            height="500"
                            style={{ border: "0" }}
                            allowFullscreen=""
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                        <iframe
                            className='tabletIframe'
                            title='tabletMap'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13305.896296719717!2d-111.97066736496721!3d33.51505789208612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b0c8f7c1849a3%3A0x9b645aa4b0b6aee0!2sCamelback%20Mountain!5e0!3m2!1sen!2sus!4v1665179552993!5m2!1sen!2sus"
                            width="700"
                            height="500"
                            style={{ border: "0" }}
                            allowFullscreen=""
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                        <iframe
                            className='tabletIframeTwo'
                            title='tabletMapTwo'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13305.896296719717!2d-111.97066736496721!3d33.51505789208612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b0c8f7c1849a3%3A0x9b645aa4b0b6aee0!2sCamelback%20Mountain!5e0!3m2!1sen!2sus!4v1665179552993!5m2!1sen!2sus"
                            width="600"
                            height="450"
                            style={{ border: "0" }}
                            allowFullscreen=""
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                        <iframe
                            className='tabletIframeThree'
                            title='tabletMapThree'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13305.896296719717!2d-111.97066736496721!3d33.51505789208612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b0c8f7c1849a3%3A0x9b645aa4b0b6aee0!2sCamelback%20Mountain!5e0!3m2!1sen!2sus!4v1665179552993!5m2!1sen!2sus"
                            width="500"
                            height="450"
                            style={{ border: "0" }}
                            allowFullscreen=""
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                        <iframe
                            className='mobileIframe'
                            title='mobileIframe'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13305.896296719717!2d-111.97066736496721!3d33.51505789208612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b0c8f7c1849a3%3A0x9b645aa4b0b6aee0!2sCamelback%20Mountain!5e0!3m2!1sen!2sus!4v1665179552993!5m2!1sen!2sus"
                            width="380"
                            height="450"
                            style={{ border: "0" }}
                            allowFullscreen=""
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                        <iframe
                            className='mobileIframeTwo'
                            title='mobileIframeTwo'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13305.896296719717!2d-111.97066736496721!3d33.51505789208612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b0c8f7c1849a3%3A0x9b645aa4b0b6aee0!2sCamelback%20Mountain!5e0!3m2!1sen!2sus!4v1665179552993!5m2!1sen!2sus"
                            width="345"
                            height="350"
                            style={{ border: "0" }}
                            allowFullscreen=""
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>

                <div id="trailMapsDiv">
                    <div id="trailMapsContainer">
                        <h2>Trail Maps</h2>
                        <img src={trailMap} alt="Map of camelback mountain trails" id='trailMap'/>
                        <a href="https://www.google.com/maps/d/viewer?mid=1ieD0OxAyurxNNxABumRhTaXjUVc&ll=33.51578686782987%2C-111.96082640740961&z=16" target="_blank" rel="noreferrer" id='trailMapLink'>Click here for interactive trail map</a>
                        <p>** Trail maps provided via the City of Phoenix, AZ.</p>
                    </div>
                </div>

            </div>

        </>
    )
}

export default Maps;