import React from 'react';
import './Info.css';

// Component imports
import BeatTheHeat from './BeatTheHeat';
import BePrepared from './BePrepared';
import Locals from './Locals';
import Rules from './Rules';
import New from './New';

// Image imports
import ArizonaBackground from './img/ArizonaBackground.png';

const Info = () => {
    return (
        <>
            <div id="InfoBackgroundContainer">
                <img src={ArizonaBackground} alt="Arizona Background" id="arizonaBackground" />
                <div className="ContentContainer containerOne">
                    <BePrepared />
                </div>
                <h2>Beat the Heat</h2>
                <div className="ContentContainer heatContainer">
                    <BeatTheHeat />
                </div>
                <h2>Meet the Locals</h2>
                <div className="ContentContainer localsContainer">
                    <Locals />
                </div>
                <h2>Know the Rules</h2>
                <div className="ContentContainer rulesContainer">
                    <Rules />
                </div>
                <h2>What's New?</h2>
                <div className="ContentContainer newContainer">
                    <New />
                </div>
            </div>
        </>
    )
}

export default Info;